import "./css/productItem.css";

const ProductItem = ({ title, img, content }) => {
  return (
    <section className="custom-product-item d-flex flex-column">
      <h3 className="custom-product-title">{title}</h3>
      <div className="custom-product-content">
        <img src={img} alt={title} className="custom-product-img" />
        <p className="custom-product-description">{content}</p>
      </div>
    </section>
  );
};

export default ProductItem;
