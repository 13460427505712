import "./css/wikipediaContent.css";

const WikipediaContent = ({ content1, content2, content3 }) => {
    return (
        <div className="wikipedia-content">
            <p>{content1}</p>
            <ul>
                {content2?.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
            <p>{content3}</p>
        </div>
    );
};

export default WikipediaContent;
