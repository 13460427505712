import Product from "../components/Product";
import Application from "../components/Application";
import Owner from "../components/Owner";
import Brand from "../components/Brand";



const Home = () =>{
    return(
        <>
            <Product/>
           <Application/>
           <Owner/>
           <Brand/>
        </>
    )
};

export default Home;