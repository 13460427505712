import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./css/footer.css"; // Unique class names ensure no impact on other components

const Footer = () => {
  return (
    <footer className="footer-container">
      {/* Footer Top Section */}
      <div className="footer-top">
        <div className="footer-content">
          {/* Left Section */}
          <div className="footer-left">
            <p>Contact us for a demo on our tracker management system</p>
            <h4 className="footer-contact-number">+91 9769360004</h4>
          </div>

          {/* Right Section - Newsletter */}
          <div className="footer-right">
            <p>Newsletter</p>
            <form className="footer-newsletter">
              <input type="email" placeholder="Your email address" className="footer-input" />
              <button type="submit" className="footer-button">Sign up</button>
            </form>
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <div className="footer-bottom-content">
          {/* Left Section */}
          <p>
            ©2024. All rights reserved.
            <Link to="/terms-of-use" className="footer-link"> Terms of Use</Link> |
            <Link to="/privacy-policy" className="footer-link"> Privacy Policy</Link>
          </p>

          {/* Social Icons */}
          <div className="footer-icons">
            <span className="footer-icon" onClick={() => window.open("https://www.facebook.com/Webpioneer", "_blank")}><FaFacebookF /></span>
            <span className="footer-icon" onClick={() => window.open("https://www.linkedin.com/in/webpioneer/", "_blank")}><FaLinkedinIn /></span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
