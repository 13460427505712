import "./css/service.css";

const Service = ({ title, service, des }) => {
  return (
    <div className="service-container">
      {/* Service Top Section */}
      <div className="service-top d-flex flex-column justify-content-center align-items-center text-center">
        <h3 className="fs-2 text-white">{title}</h3>
        <p className="fs-5 text-light">{service}</p>
      </div>

      {/* Service Bottom Section */}
      <div className="service-bottom text-center">
        <h3 className="fs-4">{des}</h3>
      </div>
    </div>
  );
};

export default Service;
