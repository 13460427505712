import { useState } from "react";
import PortItem from "./PortItem";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/portPage1.css";

// Import images
import image1 from "../assets/AAA-Realty--800x800.png";
import image2 from "../assets/Decxotyc-800x800.png";
import image3 from "../assets/Home-Priyanka-Gupta-Designs-800x800.png";
import image4 from "../assets/Janamabhoomi-News-1024x1024.png";
import image5 from "../assets/Notion-Fashionspng-800x800.png";
import image6 from "../assets/Snacked-800x800.png";
import image7 from "../assets/Naari-800x800.png";
import image8 from "../assets/Nitin-Rao-India-800x712.png";
import image9 from "../assets/SLN-Tech-800x800.png";
import image10 from "../assets/SLN-Technologies-800x800.png";
import image11 from "../assets/gopaldas-jewellerrs-800x800.png";
import image12 from "../assets/leap-for-word-800x800.png";

// Portfolio data
const portfolioItems = [
  { id: 1, img: image3, title: "Priyanka Gupta Designs", category: "WordPress" },
  { id: 2, img: image7, title: "Shopnaari", category: "E-commerce" },
  { id: 3, img: image2, title: "Livespree", category: "E-commerce" },
  { id: 4, img: image5, title: "Notion Fashions", category: "E-commerce" },
  { id: 5, img: image12, title: "Leap for Word", category: "WordPress" },
  { id: 6, img: image9, title: "SLN Tech", category: "WordPress" },
  { id: 7, img: image10, title: "SLN Technologies", category: "WordPress" },
  { id: 8, img: image6, title: "Snacked.in", category: "Opencart" },
  { id: 9, img: image1, title: "AAA Realty", category: "WordPress" },
  { id: 10, img: image11, title: "N Gopaldas Jewellers", category: "E-commerce" },
  { id: 11, img: image4, title: "Saurashtra Trust, Gujarati Newspapers", category: ".NET" },
  { id: 12, img: image8, title: "Nitin Rao", category: "WordPress" },
];

const PortPage1 = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  // Modal State
  const [modalShow, setModalShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Open Modal on Image Click
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalShow(true);
  };

  // Close Modal
  const handleClose = () => setModalShow(false);

  // Get unique categories
  const categories = ["All", ...new Set(portfolioItems.map((item) => item.category))];

  // Filter items based on category selection
  const filteredItems =
    selectedCategory === "All"
      ? portfolioItems
      : portfolioItems.filter((item) => item.category === selectedCategory);

  return (
    <div className="container mt-4 portfolio-page-container">
      {/* Filter Buttons */}
      <div className="text-center mb-4">
        {categories.map((category) => (
          <button
            key={category}
            className={`portfolio-filter-btn btn ${selectedCategory === category ? "active" : ""}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Portfolio Grid */}
      <div className="portfolio-card row gx-4 gy-4 justify-content-center">
        {filteredItems.map(({ id, img, title, category }) => (
          <div key={id} className="portfolio-card-content col-lg-4 col-md-6 col-sm-12 d-flex">
            <div className="portfolio-item-wrapper" onClick={() => handleImageClick({ img, title })}>
              <PortItem img={img} title={title} des={category} />
            </div>
          </div>
        ))}
      </div>

      {/* Modal for Image Preview */}
      <Modal show={modalShow} onHide={handleClose} centered className="portfolio-modal">
        <Modal.Body className="text-center">
          {selectedImage && (
            <>
              <img src={selectedImage.img} alt={selectedImage.title} className="portfolio-modal-img" />
              <h5 className="mt-3">{selectedImage.title}</h5>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PortPage1;
