import "./css/cart.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported

const Cart = () => {
    return (
        <section className="custom-cart d-flex flex-column justify-content-center align-items-center text-center">
            <h3>Great things are on the horizon</h3>
            <p>Something big is brewing! Our store is in the works and will be launching soon!</p>
        </section>
    );
};

export default Cart;
