import Service from "../components/Service";
import "./css/portfolio.css";
import PortPage1 from "../components/PortPage1";



const Portfolio = () =>{
    return(
        <>

            <Service title={"Portfolio"} service={"Some of our projects done"} />


            <section className="portfolio-container d-flex flex-column ">
                <div className="portfolio-item ">
                        <PortPage1/>
                </div>
            </section>


        </>
    )
};

export default Portfolio;