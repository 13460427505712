import "./css/portItem.css";

const PortItem = ({img, title, des}) =>{
    return(
        <>
            <div className="portItem-container">
                <img src={img} alt="" />
                <div className="portItem-content">
                    <h3>{title}</h3>
                    <p>{des}</p>
                </div>
            </div>
        </>
    )
}

export default PortItem;