import Service from "../components/Service";
import Business from "../components/Business";
import "./css/services.css";
import ServicesItem from "../components/ServicesItem";
import image1 from "../assets/cloud-computer-150x150.png";
import image2 from "../assets/web-development-150x150.png";
import image3 from "../assets/online-shop-150x150.png";
import image4 from "../assets/web-api.png";
import image5 from "../assets/mobile-application.png";
import image6 from "../assets/application-development.png";

const Services = () => {
  return (
    <>
      <Service title="Services" service="Technology made simple" des="We are an innovative Software Development company and provide services below." />

      <section className="services container">
        <div className="services-content"> {/* Bootstrap Grid System */}
          <div className="col-md-4 col-sm-6">
            <ServicesItem img={image1} title="Cloud Hosting Solutions" des="Now a days all distributed system/websites/applications runs on cloud to save investment and better output. We consult clients to choose best cloud services to run business after reviewing the requirements and avoid burden of infrastructure. Cloud hosting works like Total-cost-of-owner (TCO), pay only what you use. Connect us for cloud hosting services." />
          </div>

          <div className="col-md-4 col-sm-6">
            <ServicesItem img={image2} title="Web Development" des="Web application is a software application that is accessed with a web browser over a network such as the Internet or an intranet. Web applications are popular due to the ubiquity of the browser as a client, sometimes called a thin client. The ability to update and maintain web applications without distributing and installing software on potentially thousands of client computers is a key reason for their popularity." />
          </div>

          <div className="col-md-4 col-sm-6">
            <ServicesItem img={image3} title="E-commerce Solutions" des="Having your own web site design can not only help you to sell your products and services but it will drastically alter human behavior when people sell, buy, communicate and retrieve information with so many opportunities on a global platform where more than 500 million people at a time are surfing and number is growing every passing day with unprecedented rate." />
          </div>

          <div className="col-md-4 col-sm-6">
            <ServicesItem img={image4} title="API Integration" des="By integrating APIs into your product, you can better focus on evolving your core offerings. Choosing to integrate APIs benefits you by saving time and engineering hours that would have been required to build the same functionality. This allows you to offer a new experience for customers while utilizing what is already familiar and adopted." />
          </div>

          <div className="col-md-4 col-sm-6">
            <ServicesItem img={image5} title="Mobile Application Development" des="Mobile application development is the process by which application software is developed for low-power handheld devices, such as personal digital assistants, enterprise digital assistants or mobile phones. Mobile app development has been steadily growing, both in terms of revenues and jobs created. " />
          </div>

          <div className="col-md-4 col-sm-6">
            <ServicesItem img={image6} title="Application Development" des="Our application development services help you to evolving business and technology challenges by defining, designing and building applications tailored to meet your business requirements, where software evolves as it is developed piece-by-piece. Our expertise in building comprehensive solutions can solve your business objectives" />
          </div>
        </div>

        
      </section>
      <Business qus="Have a question? We're always happy to chat about growing your business." />
    </>
  );
};

export default Services;
