import React, { useState, useEffect } from "react";
import "./css/BackToTop.css";
import { MdKeyboardArrowUp } from "react-icons/md";

const BackToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={`back-to-top ${showButton ? "show" : ""}`} onClick={scrollToTop}>
      <MdKeyboardArrowUp size={20} />
    </div>
  );
};

export default BackToTop;