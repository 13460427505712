import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Cart from "./pages/Cart";
import Footer from "./components/Footer";
import Term from './pages/Term';
import WhatsAap from './components/WhatsApp.jsx';
import BackToTop from './components/BackToTop.jsx';
import Privacy from './pages/Privacy.jsx';
import WikipediaPage from './pages/WikipediaPage.jsx';

function App() {
  return (
    <>
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/portfolio' element={<Portfolio/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/cart' element={<Cart/>}/>
        <Route path='/terms-of-use' element={<Term/>}/>
        <Route path='/privacy-policy' element={<Privacy/>}/>
        <Route path='/search' element={<WikipediaPage/>}/>
      </Routes>
      <WhatsAap/>
      <BackToTop/>
      <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
