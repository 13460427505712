import Service from "../components/Service";
import "./css/term.css";

const Term = () => {
  return (
    <>
      <section className="term-container">
        <Service title={"Terms of Use"} />

        <p className="term-content">
          The following terms and conditions will be deemed to have been
          accepted by the User on usage of the website www.webpioneer.in. You
          are requested to read them carefully before you use the services of
          this site. <br />
          The term User shall refer to the user who is browsing the site. The
          term Site refers to www.webpioneer.in owned and monitored by
          Webpioneer. <br />
          By using the Site, you agree to follow and be bound by the following
          terms and conditions concerning your use of the Site. Webpioneer may
          revise the Terms of Use at any time without notice to you. Areas of
          the Site may have different terms of use posted. If there is a
          conflict between the Terms of Use and terms of use posted for a
          specific area of the Site, the latter shall have precedence with
          respect to your use of that area of the Site. <br />
          Webpioneer may terminate User’s access at any time for any reason. The
          provisions regarding to disclaimer of warranty, accuracy of
          information, and indemnification shall survive such termination.
          Webpioneer may monitor access to the Site. <br />
          All content present on this site is the exclusive property of
          Webpioneer. The software, text, images, graphics, video and audio used
          on this site belong to Webpioneer. No material from this site may be
          copied, modified, reproduced, republished, uploaded, transmitted,
          posted or distributed in any form without prior written permission
          from Webpioneer. All rights not expressly granted herein are reserved.
          Unauthorized use of the materials appearing on this site may violate
          copyright, trademark and other applicable laws, and could result in
          criminal or civil penalties. <br />
          Webpioneer does not make any warranties, express or implied,
          including, without limitation, those of merchantability and fitness
          for a particular purpose, with respect to any information, data,
          statements or products made available on the Site. <br />
          The Site, and all content, materials, information, software, products
          and services provided on the Site, are provided on an “as is” and “as
          available” basis. Webpioneer expressly disclaims all warranties of any
          kind, whether express or implied, including, but not limited to, the
          implied warranties of merchantability, fitness for a particular
          purpose and non-infringement. <br />
          Webpioneer shall have no responsibility for any damage to User’s
          computer system or loss of data that results from the download of any
          content, materials, information from the Site. <br />
          Webpioneer may change or discontinue any aspect of its website at any
          time, including, its content or features. Webpioneer reserves the
          right to change the terms and conditions applicable to use of the
          Site. Such changes shall be effective immediately upon notice, which
          shall be placed on the Site. <br />
          In no event will Webpioneer be liable for damages of any kind,
          including without limitation, direct, incidental or consequential
          damages (including, but not limited to, damages for lost profits,
          business interruption and loss of programs or information) arising out
          of the use of or inability to use Webpioneer’s website, or any
          information provided on the website, or in the Products any claim
          attributable to errors, omissions or other inaccuracies in the Product
          or interpretations thereof. Some jurisdictions do not allow the
          limitation or exclusion of liability. Accordingly, some of the above
          limitations may not apply to the User. <br />
          User agrees to indemnify, defend and hold Webpioneer harmless from and
          against all losses, expenses, damages and costs, including reasonable
          attorneys’ fees, arising out of or relating to any misuse by the User
          of the content and services provided on the Site. <br />
          The information contained in the Site has been obtained from sources
          believed to be reliable. Webpioneer disclaims all warranties as to the
          accuracy, completeness or adequacy of such information. <br />
          Webpioneer makes no warranty that: <br />
          (a) the Site will meet your requirements; <br />
          (b) the Site will be available on an uninterrupted, timely, secure, or
          error-free basis; <br />
          (c) the results that may be obtained from the use of the Site or any
          services offered through the site will be accurate or reliable. <br />
          The User’s right to privacy is of paramount importance to Webpioneer.
          Any information provided by the User will not be shared with any third
          party. Webpioneer reserves the right to use the information to provide
          the User a more personalized online experience. <br />
          The Site provides links to web sites and access to content, products
          and services from third parties, including users, advertisers,
          affiliates and sponsors of the Site. You agree that Webpioneer is not
          responsible for the availability of, and content provided on, third
          party web sites. The User is requested to peruse the policies posted
          by other web sites regarding privacy and other topics before use.
          Webpioneer is not responsible for third party content accessible
          through the Site, including opinions, advice, statements and
          advertisements, and User shall bear all risks associated with the use
          of such content. <br />
          Webpioneer is not responsible for any loss or damage of any sort User
          may incur from dealing with any third party.
        </p>
      </section>
    </>
  );
};

export default Term;
