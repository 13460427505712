import whatsappIcon from "../assets/whatsapp-logo-32x32.png";
import "./css/whatApp.css";

const WhatsApp = () => {
    const openWhatsApp = () => {
        window.open("https://web.whatsapp.com/send?phone=919769360004&text=", "_blank");
    };

    return (
        <div className="whatsapp-container" onClick={openWhatsApp}>
            <img src={whatsappIcon} alt="WhatsApp" className="whatsapp-icon" />
            <span className="whatsapp-text">WhatsApp us</span>
        </div>
    );
};

export default WhatsApp;
