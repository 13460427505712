import { IoStarSharp } from "react-icons/io5";
import "./css/owner.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap for responsiveness

const Owner = () => {
  return (
    <>
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></button>
        </div>

        <div className="carousel-inner">
          {/* Carousel Item 1 */}
          <div className="carousel-item active">
            <div className="d-flex justify-content-center">
              <div className="col-md-10 col-lg-8">
                <section className="custom-owner">
                  <div className="d-flex flex-column gap-4">
                    <h3>Snacked</h3>
                    <p>
                      We had a great experience working with Suraj and the team at Webpioneer.
                      They were very efficient, prompt, and flexible with the work we had given.
                      They helped us find affordable solutions and went two steps further by suggesting
                      plugins and inputs that increased the efficiency and user experience of our website.
                      Look forward to continuing our working relationship! Best of luck.
                    </p>
                  </div>
                  <div className="owner-info">
                    <h5>Vidhi Kapur</h5>
                    <span className="owner-stars">
                      <IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp />
                    </span>
                    <p>Co-Founder</p>
                  </div>
                </section>
              </div>
            </div>
          </div>

          {/* Carousel Item 2 */}
          <div className="carousel-item">
            <div className="d-flex justify-content-center">
              <div className="col-md-10 col-lg-8">
                <section className="custom-owner">
                  <h3>Another Testimonial</h3>
                  <p>
                    Their team was very professional and delivered the project on time with excellent quality.
                    Highly recommend working with them!
                  </p>
                  <div className="owner-info">
                    <h5>John Doe</h5>
                    <span className="owner-stars">
                      <IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp />
                    </span>
                    <p>CEO, TechCorp</p>
                  </div>
                </section>
              </div>
            </div>
          </div>

          {/* Carousel Item 3 */}
          <div className="carousel-item">
            <div className="d-flex justify-content-center">
              <div className="col-md-10 col-lg-8">
                <section className="custom-owner">
                  <h3>Awesome Experience</h3>
                  <p>
                    This team has done an amazing job. Their attention to detail and commitment to quality
                    make them a pleasure to work with.
                  </p>
                  <div className="owner-info">
                    <h5>Jane Smith</h5>
                    <span className="owner-stars">
                      <IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp /><IoStarSharp />
                    </span>
                    <p>Founder, StartUpX</p>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </>
  );
};

export default Owner;
