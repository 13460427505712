import ProductItem from "./ProductItem";
import img1 from "../assets/product1.jpg";
import img2 from "../assets/product2.jpg";
import img3 from "../assets/product3.jpg";
import "./css/product.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap for responsive design

const Product = () => {
  return (
    <section className=" custom-product-section">
      {/* Product Grid */}
      <div className="product-item  justify-content-center">
        <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
          <ProductItem
            title="Vehicle Tracking"
            img={img2}
            content="Track your vehicle in real-time and get notifications when deviating routes on Geo-fencing, object control, driver behavior."
          />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
          <ProductItem
            title="Container Tracking"
            img={img1}
            content="Our platform tracks each container in the yard or in transit across countries."
          />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
          <ProductItem
            title="Fuel Monitoring"
            img={img3}
            content="Monitor fuel usage in vehicles and optimize costs."
          />
        </div>
      </div>

      {/* Product Bottom Section */}
      <div className="custom-product-bottom text-center mt-5">
        <h3>IoT Solutions</h3>
        <p>
          IoT application development requires specialized skills and technologies. IoT solutions combine complex endpoints, back-end systems, platforms, data (like sensors, connectivity, processors, embedded software, analytics, machine learning, etc.). We provide the best solutions to centrally control devices, employees, deliveries, and logistics.
        </p>
        <button className="custom-product-btn">Read more</button>
      </div>
    </section>
  );
};

export default Product;
