import { IoMdSearch } from "react-icons/io";
import { IoCartOutline } from "react-icons/io5";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { MdOutlineMenu } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import logo from "../assets/webpioneer-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./css/header.css";

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
    const [searchOpen, setSearchOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    // Handle screen resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest(".dropdown")) {
                setDropdownOpen(false);
            }
        };

        if (dropdownOpen) {
            document.addEventListener("click", handleClickOutside);
        }

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [dropdownOpen]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        setDropdownOpen(false);
    };

    // ✅ Fixed: Now clicking the arrow toggles the dropdown correctly
    const toggleDropdown = () => {
        if (isMobile) {
            setDropdownOpen((prev) => !prev);
        }
    };

    const closeMenu = () => {
        setMenuOpen(false);
        setDropdownOpen(false);
    };

    const handleSearch = () => {
        if (searchQuery.trim()) {
            navigate(`/search?q=${searchQuery}`);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <header className="d-flex">
            <div className="header-logo">
                <img src={logo} alt="WebPioneer Logo" />
            </div>

            <div className={`header-list ${menuOpen ? "active" : ""}`}>
                <ul className="d-flex gap-5">
                    <Link to="/" onClick={closeMenu}><li>HOME</li></Link>
                    <Link to="/about" onClick={closeMenu}><li>ABOUT</li></Link>
                    <Link to="/services" onClick={closeMenu}><li>SERVICES</li></Link>
                    <Link to="/portfolio" onClick={closeMenu}><li>PORTFOLIO</li></Link>
                    <li>BLOG</li>

                    <li className={`dropdown ${dropdownOpen ? "open" : ""}`} onClick={toggleDropdown}>
                        <span className="nav-link d-flex align-items-center">
                            PORTAL ACCESS
                            <span className="icon">
                                {dropdownOpen ? <FaAngleUp /> : <FaAngleDown />}
                            </span>
                        </span>
                        <ul className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}>
                            <li><Link to="/vehicle-portal">Vehicle Portal</Link></li>
                            <li><Link to="/tank-portal">Tank Portal</Link></li>
                        </ul>
                    </li>

                    <Link to="/contact" onClick={closeMenu}><li>CONTACT</li></Link>

                    <li className="search-container icon1"
                        onMouseEnter={() => setSearchOpen(true)}
                        onMouseLeave={() => setSearchOpen(false)}
                    >
                        <IoMdSearch size={20} />
                        <div className={`search-dropdown ${searchOpen ? "show" : ""}`}>
                            <div className="search-input-container">
                                <input 
                                    type="text" 
                                    placeholder="Enter a keyword to search..." 
                                    value={searchQuery} 
                                    onChange={(e) => setSearchQuery(e.target.value)} 
                                    onKeyDown={handleKeyDown} 
                                />
                                <Link to={`/search?q=${searchQuery}`}>
                                    <IoMdSearch className="search-icon" size={18} />
                                </Link>
                            </div>
                        </div>
                    </li>

                    <li className="cart-container icon1">
                        <IoCartOutline size={20} />
                        <div className="cart-dropdown">
                            <p>No products in the cart.</p>
                            <Link to="/cart">
                                <button className="cart-btn">View Cart <IoCartOutline /></button>
                            </Link>
                            <p className="cart-total">Subtotal: <strong>$0.00</strong></p>
                        </div>
                    </li>
                </ul>

                {menuOpen && (
                    <div className="close-menu" onClick={toggleMenu}>
                        <RxCross1 />
                    </div>
                )}
            </div>

            {!menuOpen && (
                <div className="mobile-menu" onClick={toggleMenu}>
                    <MdOutlineMenu />
                </div>
            )}
        </header>
    );
};

export default Header;
