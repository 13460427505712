import ServicesItem from "./ServicesItem";
import image1 from "../assets/api-integration.svg";
import image2 from "../assets/hybrid-new.svg";
import image3 from "../assets/cloud-hosting.svg";
import "./css/application.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap for responsiveness

const Application = () => {
  return (
    <section className=" custom-application">
      <div className="application-item row justify-content-center">
        <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
          <ServicesItem 
            title="PAYMENT / API INTEGRATION" 
            img={image1} 
            des="Payment / API Integration comprises of tools that a business can easily deploy to club business applications with payments processing. We take extra care for your business derivatives by integrating payments along with your business tools." 
          />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
          <ServicesItem 
            title="MOBILE DEVELOPMENT" 
            img={image2} 
            des="Mobile Application Development is all about developing an application that has the capability to work on Mobile Phones. Webpioneer possess technical expertise to develop Hybrid and Native mobile applications." 
          />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
          <ServicesItem 
            title="CLOUD HOSTING SOLUTION" 
            img={image3} 
            des="We are specialized resources to handle by hosting web applications on AWS, Google Cloud, Azure while handling Load Balancing, CDN, RDBMS, and Security. Ready to migrate to the cloud?" 
          />
        </div>
      </div>
    </section>
  );
};

export default Application;
