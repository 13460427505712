import image1 from "../assets/logo9.png";
import image2 from "../assets/logo11.png";
import image3 from "../assets/logo12.png";
import image4 from "../assets/logo13.png";
import image5 from "../assets/NOTION_s_1.png";
import "./css/brand.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap for responsiveness

const Brand = () => {
  return (
    <section className="container custom-brand text-center">
      <div className="row justify-content-center align-items-center">
        {[image2, image4, image3, image5, image1].map((image, index) => (
          <div className="col-lg-2 col-md-3 col-4 d-flex justify-content-center" key={index}>
            <img src={image} alt={`Brand Logo ${index + 1}`} className="custom-brand-img" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Brand;
