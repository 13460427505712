import Service from "../components/Service";
import "./css/about.css";
import img from "../assets/business-flow.png";
import Business from "../components/Business";

const About = () => {
  return (
    <>
      <Service
        title="About"
        service="An IT Services & Solutions Company"
        des="We provide innovative solutions to lead in a competitive market."
      />

      <section className="about-container">
        {/* About Top Section */}
        <div className="about-top container py-4">
          <div className="about-top-content g-4 text-center px-2 d-flex flex-column flex-md-row justify-content-center align-items-center py-5">
            <div className="col-md-6">
              <h5>What we do</h5>
              <p>
                We provide solutions and analyze, develop applications to
                overcome challenges and automate business processes globally.
              </p>
            </div>
            <div className="col-md-6">
              <h5>Our Approach</h5>
              <p>
                We collaborate with clients, create experiences based on
                strategic goals, and test them with real-time applications in
                iterative cycles.
              </p>
            </div>
          </div>
          <div className="text-center mt-4">
            <p className="fs-5 text-muted">
              We deliver innovative web solutions to help clients leverage
              business and technology opportunities.
            </p>
          </div>
        </div>

        {/* About Bottom Section */}
        <div className="about-bottom text-center mt-4">
          <img src={img} alt="Business Flow" className="img-fluid" loading="lazy" />
        </div>

      </section>
      
        {/* Business Inquiry */}
        <Business
          qus="Have a question? We're always happy to chat about growing your business using the latest technology and marketing strategies!"
        />
    </>
  );
};

export default About;
