import "./css/servicesItem.css";

const ServicesItem = ({ img, title, des }) => {
  return (
    <div className="services-item card p-3 h-100"> {/* Bootstrap card with equal height */}
      <div className="image-container">
        <img className="service-img" src={img} alt={title} />
      </div>
      <div className="content">
        <h4 className="service-title">{title}</h4>
        <p className="service-description">{des}</p>
      </div>
    </div>
  );
};

export default ServicesItem;
