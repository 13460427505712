import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import Service from "../components/Service";
import "./css/Contact.css"; // Importing CSS file

const Contact = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    your_email: "",
    message: "",
  });

  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = "service_1dk1v96";
    const templateID = "template_9mqsyl8";
    const publicKey = "GIpK3C2N882_gEp5D";

    const emailData = {
      to_name: "Webpioneer",
      from_name: formData.from_name,
      your_email: formData.your_email,
      message: formData.message,
    };

    emailjs
      .send(serviceID, templateID, emailData, publicKey)
      .then(() => {
        setStatus("✅ Message sent successfully!");
        setFormData({ from_name: "", your_email: "", message: "" });
      })
      .catch((error) => {
        setStatus(`❌ Failed to send message. Error: ${error.text || error}`);
      });
  };

  return (
    <>
      <Service title={"Contact"} service={"Get in Touch with WebPioneer!"} />
      <div className="contact-container">
        <div className="contact-box">
          <h2 className="contact-title">Get in Touch</h2>
          <p className="contact-subtitle">We’d love to hear from you! Fill out the form below.</p>

          <form onSubmit={handleSubmit} className="contact-form">
            <label className="form-label">Your Name *</label>
            <input type="text" name="from_name" className="form-input" placeholder="Enter your name" required value={formData.from_name} onChange={handleChange} />

            <label className="form-label">Your Email *</label>
            <input type="email" name="your_email" className="form-input" placeholder="Enter your email" required value={formData.your_email} onChange={handleChange} />

            <label className="form-label">Message *</label>
            <textarea name="message" className="form-textarea" placeholder="Write your message" required value={formData.message} onChange={handleChange} />

            <button type="submit" className="form-button">Send Message</button>
            <p className="form-status">{status}</p>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
