import "./css/business.css"

const Business = ({qus}) => {
  return (
    <>
      <div className="business mt-3">
        <div className="d-flex flex-column gap-4">
          <h5>Ready to get started?</h5>
          <p>{qus}</p>
        </div>
        <button>Get Started</button>
      </div>
    </>
  )
}

export default Business;
