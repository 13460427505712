import Service from "../components/Service";
import "./css/Privacy.css";


const Privacy = () => {
  return (

    <>

   <Service title={"Privacy Policy"}/>
    <div className="container privacy-container">
      <div className="privacy-content">
        <p className="privacy-description">
          Webpioneer has created this privacy statement in order to demonstrate
          our firm’s commitment to privacy. The following discloses our
          information gathering and dissemination practices for www.webpioneer.in</p>

        <p className="privacy-description">
          We use your IP address to help diagnose problems with our server
          and/or to administer our website. This gives us an idea of which parts
          of our site users are visiting. We do not link IP addresses to
          anything personally identifiable. This means that a user’s session
          will be tracked, but the user will be anonymous.
        </p>

        <p className="privacy-description">
          Our site’s registration form requires you to give us contact
          information like your name and email address. We use this contact
          information from the registration form to send you information about
          our company. Users may opt out of receiving future mailings by
          choosing the unsubscribe option in the registration form.
        </p>

        <p className="privacy-description">
          This site contains links to other sites. Webpioneer is not responsible
          for the privacy practices or the content of such websites.
        </p>

        <h2 className="privacy-subtitle">Objective of Policy:</h2>
        <p className="privacy-description">
          Webpioneer takes seriously the trust you place in us. To prevent
          unauthorized access or disclosure, to maintain data accuracy, and to
          ensure the appropriate use of information, Webpioneer utilizes
          appropriate physical, technical, and administrative procedures to
          safeguard the information we collect.
        </p>

        <h2 className="privacy-subtitle">Mailers</h2>
        <p className="privacy-description">
          Webpioneer may, if you so choose, send direct mailers to you at the
          address given by you.
        </p>

        <h2 className="privacy-subtitle">Security</h2>
        <p className="privacy-description">
          This site has security measures in place to protect the loss, misuse,
          and/or alteration of information under our control. The data resides
          behind a firewall, with access restricted to authorized Webpioneer
          personnel.
        </p>

        <h2 className="privacy-subtitle">Changes to this Policy</h2>
        <p className="privacy-description">
          Please note that this Policy may change from time to time. We will not
          reduce your rights under this Policy without your explicit consent. We
          will post any Policy changes on this page. We will also keep prior
          versions of this Policy in an archive for your review.
        </p>

        <h2 className="privacy-subtitle">Contacting the Website</h2>
        <p className="privacy-description">
          If you have any questions about this privacy statement, the practices
          of this site, or your dealings with this website, you can contact
          Webpioneer.
        </p>
      </div>
    </div>

    </>
  );
};

export default Privacy;
